import heroGif from './static/heroGif.gif'
import hubPhoto from './static/hubphoto.jpeg'
import lokacija from './static/location.png'
import salazasastanke from './static/salazasastanke.jpg'
import kvart from './static/kvart.png'
import ddvani from './static/ddvanka.jpg'
import ulaz from './static/ulaz.jpg'
import ured from './static/ured.jpg'
import sdzlogo from './static/SDZ_logo_color.png'
import pravomisto from './static/pravomisto_crni.png'
import mask from './static/03.svg'
import mask2 from './static/06.svg'
import mask3 from './static/07.svg'
import logo from './static/DD_Logo_color.svg'
import { Analytics } from "@vercel/analytics/react"
import mask4 from './static/01.svg'
import React, { useState, useEffect } from 'react';
import { Sun, Moon, Code, Users, Rocket, BarChart3, Lightbulb, ExternalLink, MapPin, Wifi, Coffee, Printer, VideoIcon } from 'lucide-react';

const sections = [
  { id: 'home', name: 'Početna' },
  { id: 'dd', name:'Digitalna Dalmacija'},
  { id: 'location', name: "Lokacija"},
  { id: 'tender', name: 'Natječaj' },
  { id: 'coworking', name: 'Coworking' },
  { id: 'activities', name: 'Aktivnosti' },
];

const MaskedImage = ({ hubPhoto, className, mask }) => {
  return (
    
    <div className={`${className}`}>
      <div
        className="w-full h-full"
        style={{
          maskImage: `url(${mask})`,
          WebkitMaskImage: `url(${mask})`,
          maskSize: 'contain',
          WebkitMaskSize: 'contain',
          maskRepeat: 'no-repeat',
          WebkitMaskRepeat: 'no-repeat',
          maskPosition: 'center',
          WebkitMaskPosition: 'center',
        }}
      >
        <img
          src={hubPhoto}
          alt="Startup natječaj"
          className={`w-full h-full object-cover rounded-lg-`}
        />
      </div>
    </div>
  );
};

export default function TehnoloskiHubDigitalnaDalmacija() {
  const [activeSection, setActiveSection] = useState('home');
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i].id);
        if (section.offsetTop <= scrollPosition + windowHeight / 2) {
          setActiveSection(sections[i].id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    
    <div className="bg-white dark:bg-gray-900 transition-colors duration-300">
      {/* Navigation Dots */}
      <div className="fixed right-4 top-1/2 transform -translate-y-1/2 z-50">
        <div className="flex flex-col items-center space-y-4">
          {sections.map((section) => (
            <button
              key={section.id}
              onClick={() => scrollToSection(section.id)}
              className={`w-3 h-3 rounded-full ${activeSection === section.id
                  ? 'bg-[#007FAB] dark:bg-[#DBAF32]'
                  : 'bg-gray-300 dark:bg-gray-600'
                }`}
              aria-label={section.name}
            />
          ))}
        </div>
      </div>
      <Analytics/>

      {/* Dark Mode Toggle */}
      <button
        onClick={toggleDarkMode}
        className="fixed top-4 right-4 z-50 p-2 rounded-full bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-200"
      >
        {darkMode ? <Sun className="h-6 w-6" /> : <Moon className="h-6 w-6" />}
      </button>

      <main>
        {/* Hero section */}
        <div id="home" className="relative h-screen" >
          <img
            src={heroGif}
            alt="Tehnološki Hub Digitalna Dalmacija"
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className={`absolute inset-0 bg-black opacity-60`}></div>
          <div className="absolute bottom-0 left-0 p-8 md:p-16 max-w-2xl">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl mb-6">
              Županijski tehnološki hub Digitalna Dalmacija
            </h1>
            <p className="text-lg leading-8 text-gray-300 mb-8">
              Osnažujemo inovacije u srcu Dalmacije. Pridružite se našem rastućem tehnološkom ekosustavu i oblikujte budućnost tehnologije.
            </p>
            <div className="flex flex-wrap gap-4">
              <a
                href="#tender"
                className="rounded-md bg-[#007FAB] dark:bg-[#DBAF32] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#DBAF32] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#DBAF32]"
                onClick={(e) => { e.preventDefault(); scrollToSection('coworking'); }}
              >
                Istraži coworking
              </a>
              <a
                href="#coworking"
                className="text-sm font-semibold leading-6 text-white py-2.5 hover:text-[#DBAF32]"
                onClick={(e) => { e.preventDefault(); scrollToSection('tender'); }}
              >
                Akceleratorski program <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>

        {/* DD section */}
        <div id="dd" className="bg-white dark:bg-gray-900 py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="lg:w-1/2 lg:pr-8">
                <h2 className="text-base font-semibold leading-7 text-[#007FAB] dark:text-[#DBAF32]">Što je Digitalna Dalmacija?</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                  Digitalna Dalmacija
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                  <b>Digitalna Dalmacija</b> je <b>HUB</b> digitalne transformacije
                  u Splitsko-dalmatinskoj županiji s ciljem jačanja IT/Tech sektora.<br /><br />
                  Ključna područja djelovanja su: 
                </p>
                <div className="mt-10 space-y-4">
                  {[
                    { name: 'Digitalne vještine', description: 'Programi i radionice za unapređenje digitalnih kompetencija za sve dobne skupine.', icon: Code, link: "https://digitalnadalmacija.hr/edukacije"},
                    { name: 'Startup ekosustav', description: 'Edukacije, podrška i razvoj startup projekata i poduzetničkih inicijativa u IT sektoru.', icon: Rocket, link: "https://digitalnadalmacija.hr/itposlovanje" },
                    { name: 'Tech zajednica', description: 'Okupljanje i povezivanje tehnoloških entuzijasta kroz različite meetupe i konferencije.', icon: Users, link:"https://digitalnadalmacija.hr/zajednica" }
                  ].map((feature) => (
                    <div key={feature.name} className="flex items-start">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#007FAB] dark:bg-[#DBAF32] text-white">
                          <feature.icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">{feature.name}</h3>
                        <p className="mt-2 text-base text-gray-500 dark:text-gray-300">{feature.description}</p>
                        <a
                          href={feature.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`inline-flex items-center dark:text-[#DBAF32] dark:hover:text-white'
                            text-[#007FAB] hover:text-[#DBAF32]
                             transition-colors`}
                        >
                          Više
                          <ExternalLink size={16} className="ml-1" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="lg:w-1/2 lg:pl-8 mt-10 lg:mt-0">
                <div className="relative w-full overflow-hidden" style={{ paddingBottom: '50vh' }}>

                  <MaskedImage hubPhoto={ddvani} mask={mask2} className="absolute top-0 left-0 w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* location section */}
        <div id="location" className="bg-gray-100 dark:bg-gray-800 py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">

              <div className="lg:w-1/2 lg:pr-8 lg:pl-8 order-1 lg:order-2">
                <h2 className="text-base font-semibold leading-7 text-[#007FAB] dark:text-[#DBAF32]">Gdje se nalazi Digitalna Dalmacija?</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                  Lokacija
                </p>
                
                <div className="mt-10 space-y-4">
                  {[
                    { name: 'Županijski tehnološki hub Digitalna Dalmacija', icon: MapPin }
                  ].map((item) => (
                    <div key={item.name} className="flex items-center">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#007FAB] dark:bg-[#DBAF32] text-white">
                          <item.icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">{item.name}</h3>
                        <a
                          href="https://maps.app.goo.gl/1PwmBwJnYzqnrqqf8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`inline-flex items-center dark:text-[#DBAF32] dark:hover:text-white'
                            text-[#007FAB] hover:text-[#DBAF32]
                             transition-colors mt-2 text-base text-gray-500 dark:text-gray-300 underline`}
                        >
                          Ulica Ruđera Boškovića 25, 21000 Split
                          <ExternalLink size={16} className="ml-1" />
                        </a>
                        
                      </div>
                    </div>
                  ))}
                </div>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                  Tehnološki hub se nalazi na Splitu 3, u srcu Splita, nadomak sveučilišnog kampusa, u blizini mnogih tehnoloških tvrtki.
                </p>
              </div>

              <div className="lg:w-1/2 lg:pl-8 order-2 lg:order-1">
                <div className="relative w-full overflow-hidden" style={{ paddingBottom: '50vh' }}>


<MaskedImage hubPhoto={kvart} mask={mask4} className="absolute top-0 left-0 w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tender section */}
        <div id="tender" className="bg-white dark:bg-gray-900 py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="lg:w-1/2 lg:pr-8">
                <h2 className="text-base font-semibold leading-7 text-[#007FAB] dark:text-[#DBAF32]">Akceleratorski program</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                  Pridružite se akceleratorskom programu
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                 Natječaj za ovogodišnji akceleratorski program je završio. Pratite nas za sve buduće aktivnosti.
                </p>
                <div className="mt-10 flex flex-wrap gap-4">
                 {/* <a
                    href="#"
                    className="rounded-md bg-[#007FAB] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#DBAF32] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#007FAB]"
                  >
                    Iskaži interes
                  </a>
                 <a href="#" className="text-sm py-2.5 font-semibold leading-6 text-gray-900 dark:text-white hover:text-[#007FAB] dark:hover:text-[#DBAF32]">
                    Saznaj Više <span aria-hidden="true">→</span>
                  </a> */}
                </div>
              </div>
              <div className="lg:w-1/2 lg:pl-8 mt-10 lg:mt-0">
                <div className="relative w-full overflow-hidden" style={{ paddingBottom: '50vh' }}>
                  
                  <MaskedImage hubPhoto={ured} mask={mask4} className="absolute top-0 left-0 w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Coworking section */}
        <div id="coworking" className="bg-gray-100 dark:bg-gray-800 py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="lg:w-1/2 lg:pl-8 order-2 lg:order-1">
                <div className="relative w-full overflow-hidden" style={{ paddingBottom: '50vh' }}>
                  <MaskedImage hubPhoto={ulaz} mask={mask3}
                    alt="Coworking prostor"
                    className="absolute top-0 right-0 
                     h-full w-full  object-cover rounded-lg"
                  />
                </div>
              </div>
              <div className="lg:w-1/2 lg:pr-8 lg:pl-8 order-1 lg:order-2">
                <h2 className="text-base font-semibold leading-7 text-[#007FAB] dark:text-[#DBAF32]">Fleksibilan radni prostor</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                  Coworking u Digitalnoj Dalmaciji
                </p>
                <p className="text-base text-gray-500 dark:text-gray-300 mb-6">
    Coworking prostor je otvoren svakim radnim danom, a sve pogodnosti su dostupne isključivo članovima. Prijave za članstvo su otvorene.
  </p>

  <div className="mt-10 space-y-4">
    {[
      { name: 'Besplatni WiFi', description: 'Brza i pouzdana internetska veza za neometan rad', icon: Wifi },
      { name: 'Radni prostori', description: 'Udobni stolovi i lounge zona za različite radne stilove', icon: Users },
      { name: 'Osvježenja', description: 'Bar s raznovrsnim pićima i neograničena kava', icon: Coffee },
      { name: 'Sale za sastanke', description: 'Profesionalno opremljeni prostori za sastanke i prezentacije', icon: VideoIcon },
      { name: 'Uredska oprema', description: 'Multifunkcijski uređaj za ispis i druge uredske potrebe', icon: Printer },
    ].map((feature) => (
      <div key={feature.name} className="flex items-start">
        <div className="flex-shrink-0">
          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#007FAB] dark:bg-[#DBAF32] text-white">
            <feature.icon className="h-6 w-6" />
          </div>
        </div>
        <div className="ml-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">{feature.name}</h3>
          <p className="mt-2 text-base text-gray-500 dark:text-gray-300">{feature.description}</p>
        </div>
      </div>
    ))}
  </div>

  <div className="mt-10 flex flex-wrap gap-4">
    <a
      href="https://creatorapp.zohopublic.com/brcicd/tech-hub-membership/form-perma/Tech_Hub_DD_member/DRk1FmxfQ6Cur2OQz7SfENx89XVme4hV1A4O80bRqY3dsuw8eMAsvdnWJGsR8qjXPfwUnFmRFsDNugNJGKn097vrgWjfeY6PZsYO"
      className="rounded-md bg-[#007FAB] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#DBAF32] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#007FAB]"
    >
      Prijavi se već danas
    </a>
    {/* <a href="#" className="text-sm py-2.5 font-semibold leading-6 text-gray-900 dark:text-white hover:text-[#007FAB] dark:hover:text-[#DBAF32]">
      Saznaj Više <span aria-hidden="true">→</span>
    </a> */}
  </div>
                
              </div>
            </div>
          </div>
        </div>

        {/* Activities section */}
        <div id="activities" className={`py-24 sm:py-32 bg-center bg-cover bg-no-repeat bg-opacity-40`}>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="lg:w-1/2 lg:pr-8">
                <h2 className="text-base font-semibold leading-7 text-[#007FAB] dark:text-[#DBAF32]">Značajke Digitalne Dalmacije</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                  Najsuvremeniji objekti i aktivnosti
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                  Otkrijte naše laboratorije, događaje umrežavanja i resurse osmišljene za poticanje inovacija i rasta u tehnološkoj zajednici.
                </p>
                <div className="mt-10 space-y-4">
                  {[
                    { name: 'AI i cyber laboratorij', description: 'Laboratoriji za razvoj AI-a i Cyber Securitya', icon: Code },
                    { name: 'Coworking', description: 'Prostor gdje zajednica može razvijati svoje projekte', icon: Lightbulb },
                    { name: 'Sale za sastanke', description: 'Profesionalno opremljeni prostori za sastanke i prezentacije', icon: Users },
                    { name: 'Događaji umrežavanja', description: 'Redoviti susreti i konferencije za tehnološku zajednicu', icon: BarChart3 },
                  ].map((feature) => (
                    <div key={feature.name} className="flex items-start">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#007FAB] dark:bg-[#DBAF32] text-white">
                          <feature.icon className="h-6 w-6" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">{feature.name}</h3>
                        <p className="mt-2 text-base text-gray-500 dark:text-gray-300">{feature.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="lg:w-1/2 lg:pl-8 mt-10 lg:mt-0">
                <div className="relative w-full overflow-hidden" style={{ paddingBottom: '50vh' }}>
                  <MaskedImage hubPhoto={salazasastanke} mask={mask2}
                    alt="Aktivnosti u hubu"
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg shadow-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA section */}
        <div className="bg-[#007FAB] dark:bg-[#DBAF32]">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Spremni za inovacije?<br />
              Pridružite se našoj tehnološkoj zajednici danas.
            </h2>
            <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
              <a
                href="https://creatorapp.zohopublic.com/brcicd/tech-hub-membership/form-perma/Tech_Hub_DD_member/DRk1FmxfQ6Cur2OQz7SfENx89XVme4hV1A4O80bRqY3dsuw8eMAsvdnWJGsR8qjXPfwUnFmRFsDNugNJGKn097vrgWjfeY6PZsYO"


className = "rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-[#007FAB] dark:text-[#DBAF32] shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
  >
  Prijavi se već danas
              </a >
 {/* <a href="#" className="text-sm font-semibold leading-6 text-white">
    Saznaj više <span aria-hidden="true">→</span>
  </a>*/ }
            </div >
          </div >
        </div >
      </main >

  {/* Footer */ }
  < footer className = "bg-white dark:bg-gray-800" aria-labelledby="footer-heading" >
        <h2 id="footer-heading" className="sr-only">
          Podnožje
        </h2>
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
          <div className="lg:grid lg:grid-cols-8 lg:gap-3 flex-center">
            <img
              className="h-9"
              src={logo}
              alt=" Digitalna Dalmacija"
            />
              
                        <img
              className="h-7"
              src={pravomisto}
              alt="Pravo Misto"
            />

<img
              className="h-9"
              src={sdzlogo}
              alt="Splitsko-dalmatinska Županija"
            />
            {/*<div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">Usluge</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {['Coworking', 'Akcelerator', 'AI Laboratorij', 'Cyber Laboratorij'].map((item) => (
                      <li key={item}>
                        <a href="#" className="text-sm leading-6 text-gray-600 dark:text-gray-300 hover:text-[#007FAB] dark:hover:text-[#DBAF32]">
                          {item}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">Podrška</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {['Česta Pitanja', 'Kontakt', 'Zajednica', 'Resursi'].map((item) => (
                      <li key={item}>
                        <a href="#" className="text-sm leading-6 text-gray-600 dark:text-gray-300 hover:text-[#007FAB] dark:hover:text-[#DBAF32]">
                          {item}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">Kompanija</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {['O nama', 'Blog', 'Poslovi', 'Mediji', 'Partneri'].map((item) => (
                      <li key={item}>
                        <a href="#" className="text-sm leading-6 text-gray-600 dark:text-gray-300 hover:text-[#007FAB] dark:hover:text-[#DBAF32]">
                          {item}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">Pravno</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {['Privatnost', 'Uvjeti Korištenja', 'Politika Kolačića'].map((item) => (
                      <li key={item}>
                        <a href="#" className="text-sm leading-6 text-gray-600 dark:text-gray-300 hover:text-[#007FAB] dark:hover:text-[#DBAF32]">
                          {item}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>*/}
          </div>
          <div className="mt-16 border-t border-gray-900/10 dark:border-gray-100/10 pt-8 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-gray-500 dark:text-gray-400">&copy; 2024 Digitalna Dalmacija. Sva prava pridržana.</p>
          </div>
        </div>
      </footer >
    </div >
  );
}
